// Fonts
@import url("https://use.typekit.net/ccj8sqf.css");

@font-face {
    font-family: 'Veneer';
    src: url('../fonts/Veneer.woff2') format('woff2'),
        url('../fonts/Veneer.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@mixin veneer {
    font-family: 'Veneer', sans-serif;
    font-weight: 400;
}
@mixin azo-thin {
    font-family: azo-sans-web, sans-serif; 
    font-weight: 200;
}
@mixin azo-regular {
    font-family: azo-sans-web, sans-serif; 
    font-weight: 400;
}
@mixin azo-bold {
    font-family: azo-sans-web, sans-serif; 
    font-weight: 700;
}
@mixin azo-black {
    font-family: azo-sans-web, sans-serif; 
    font-weight: 900;
}

// Mono
$white:#fff;
$black: #26303B;
$fontdark: #26303B;

// Colours
$lightgreen: #B5CC78;
$green: #91BA45;
$midgreen: #00784F;
$darkgreen: #2E451C;

$lightblue: #BFE3E8;
$blue: #8FCCCF;
$darkblue: #45B5B8;

$purple: #9673AB;
$orange: #DB7D3B;

// General
$fontawesome: 'Font Awesome 5 Pro';
$animation: all .25s ease-in-out;
$animationCubic: all 0.25s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
$borderRadius: 10px;

// Mixin
@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}
@mixin bgimg($size: cover, $pos: 50% 50%, $repeat: no-repeat) {
    background-size: $size;
    background-position: $pos;
    background-repeat: $repeat;
}
@mixin centerPos {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@mixin link-animation {
    transition: all 0.2s cubic-bezier(0.8, 0.1, 0.1, 0.8);
}

// Breakpoints
$breakpoint-xxl: 1700px;
$breakpoint-xl: 1500px;
$breakpoint-lg: 1100px;
$breakpoint-md: 768px;
$breakpoint-sm: 600px;
$breakpoint-xs: 450px;