@media screen and (max-width: 1024px) {
    header {
        .header {
            &--content {
                padding-top: 175px;
                .video-holder {
                    height: 500px;
                }
            }
            &--shape {
                left: -70px;
                transform: none;
            }
        }
        .left-clouds, .right-clouds {
            display: none;
        }
    }
    .container {
        &--vision {
            padding: 0 0 28rem;
            .top {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                padding: 65px 30px 30px;
                .image {
                    position: relative;
                    width: 100%;
                    max-width: 75%;
                    margin-top: 50px;
                    border-radius: $borderRadius;
                    order: 2;
                }
                .copy {

                }
            }
            .bottom {
                bottom: -210px;
            }
        }
        &--why {
            padding-top: 60px;
            .why {
                &-top {
                   display: none;
                }
                &-lockup {
                    margin-top: 0;
                    svg {
                        position: relative;
                        bottom: -30px;
                        right: 140px;
                        width: 150%;
                    }
                }
            }
        }
        &--pillars {
            .content {
                .carousel-top {
                    bottom: 0;
                }
            }
        }
        &--carousel {
            padding: 0 30px;
            .carousel {
                &--item {
                    border-color: transparent;
                }
            }
        }
    }
    .ReactModal__Content {
        button {
            position: absolute;
            top: -45px;
            right: 30px;
        }
    }
}

@media screen and (max-width: 640px) {
    h1 {
        font-size: 4rem;
    }
    h2 {
        font-size: 3rem;
    }
    h3 {
        font-size: 2.4rem;
    }
    h4 {
        font-size: 2rem;
    }
    h5 {
        font-size: 1.4rem;
    }
    h6 {
        font-size: 1rem;
    }
    header {
        padding-bottom: 30px;
        .header {
            &--top {
                a {
                    &:first-child {
                        margin-bottom: 20px;
                    }
                }
                &.full {
                    padding: 30px 30px 10px;
                }
            }
            &--content {
                padding-top: 275px;
                .video-holder {
                    height: 350px;
                    margin-top: -45px;
                    .play-button {
                        width: 80px;
                    }
                }
                .shapes {
                    display: none;
                }
            }
            &--shape {
                bottom: 110px;   
            }
            &--bottom {
                bottom: -270px;
            }
        }
        &.fixed {
            .header {
                &--top {
                    &.full {
                        a {
                            font-size: 1.8rem;
                            &:not(:last-child) {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    footer {
        a {
            display: block;
            width: 100%;
            margin: 0 0 10px;
        }
        p {
            margin-top: 30px;
            font-size: 1rem;
        }
    }
    .container {
        &--vision {
            .top {
                padding: 65px 15px 30px;
                .copy {
                    h2 {
                        margin-bottom: 2rem;
                    }
                    p {
                        margin-bottom: 40px;
                    }
                }
                .image {
                    max-width: 100%;
                    height: 400px;
                }
            }
        }
        &--pillars {
            .content {
                .heading {
                    margin-bottom: 50px;
                }
                .pillars-boxes {
                    .box {
                        padding: 50px 30px;
                        h4 {
                            margin-bottom: 10px;
                        }
                        p {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
        &--carousel {
            padding: 0 15px;
            .copy {
                padding: 0 15px;
                margin-bottom: 0;
                p {
                    font-size: 40px;
                    line-height: 55px;
                }
            }
            .carousel {
                .slick-current {
                    height: 345px;
                }
            }
        }
    }
    .ReactModal__Content {
        video {
            max-height: 50vh;
        }
        button {
            position: absolute;
            top: 0;
            left: 50%;
            right: auto;
            transform: translateX(-50%);
        }
    }
}