* {
    box-sizing:border-box;
}

h1, h2, h3, h4, h5, h6 {
    @include veneer;
    color:$white;
}

h1 {
    font-size: 6rem;
}
h2 {
    font-size: 5rem;
    margin-bottom: 4rem;
}
h3 {
    margin-bottom: 1.3rem;
    font-size: 4rem;
}
h4 {
    font-size: 3rem;
}
h5 {
    font-size: 2rem;
}
h6 {
    font-size: 1rem;
}

p {
    margin-bottom: 2.5rem;
    color: $black;
    font-size: 1.3rem;
    line-height: 1.5;
    strong {
        @include azo-bold;
    }
}

html, body {
    @include azo-regular;
    color: $fontdark;
}

body {
    background: $blue;
}

header {
    position: relative;
    padding-bottom: 190px;
    overflow: hidden;
    z-index: 999;
    .header {
        &--top {
            position: fixed;
            top: 0;
            width: 100%;
            padding: 65px 0 10px;
            z-index: 10;
            transition: $animation;
            a {
                margin: 0 25px;
                @include veneer;
                color: $black;
                font-size: 2.3rem;
                @include link-animation;
                user-select: none;
                &:hover {
                    color: $midgreen;
                }
            }
            &.hidden {
                transform: translateY(-100%);
                width: 100%;
                background: $white;
                @include link-animation;
            }
        }
        &--content {
            position: relative;
            padding-top: 300px;
            z-index: 2;
            .logo {
                picture {
                    display: flex;
                    justify-content: center;
                    img {
                        max-width: 90%;
                    }
                }
            }
            .video-holder {
                position: relative;
                height: 670px;
                margin-top: -75px;
                border: 10px solid $white;
                border-radius: $borderRadius;
                cursor: pointer;
                picture {
                    position: relative;
                    display: flex;
                    width: 100%;
                    height: 100%;
                    img {
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                    }
                    &:after {
                        @include pseudo;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba($green, 0.2);
                    }
                }
                .play-button {
                    position: absolute;
                    @include centerPos;
                    width: 130px;
                    @include link-animation;
                    z-index: 9;
                }
                &:hover {
                    .play-button {
                        width: 150px;
                    }
                }
            }
            .shapes {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: -1;
                svg {
                    position: absolute;
                    &.tree {
                        top: -198px;
                        left: -30px;
                        z-index: 1;
                    }
                    &.light-green-shrub {
                        top: 56px;
                        left: -110px;
                        width: 183px;
                        z-index: 2;
                    }
                    &.dark-green-shrub {
                        top: 110px;
                        left: 120px;
                        width: 120px;
                        z-index: 3;
                    }
                }
            }
        }
        &--shape {
            position: absolute;
            bottom: 170px;
            left: 50%;
            transform: translateX(-50%);
            width: 110%;
            min-width: 2000px;
        }
        &--bottom {
            position: absolute;
            bottom: -153px;
            width: 100%;
            min-width: 2000px;
            height: auto;
            pointer-events: none;
            z-index: 1;
        }
    }
    .left-clouds {
        position: absolute;
        top: 170px;
        width: 300px;
        height: auto;
        animation: linear infinite forwards;
        animation-name: clouds;
        animation-duration: 60s;
        user-select: none;
        z-index: 0;
    }
    .right-clouds {
        position: absolute;
        top: 270px;
        width: 300px;
        height: auto;
        animation: linear infinite forwards;
        animation-name: clouds;
        animation-duration: 60s;
        animation-delay: -40s;
        user-select: none;
        z-index: 0;
    }
    &.fixed {
        .header {
            &--top {
                padding: 20px 0 15px;
                background: $white;
            }
        }
    }
}

footer {
    position: relative;
    padding: 160px 0 90px;
    font-size: 20px;
    line-height: 35px;
    background: $blue;
    overflow: hidden;
    a {
        margin: 0 25px;
        @include azo-bold;
        color: $fontdark;
        transition: $animation;
        &:hover {
            color: $midgreen;
        }
    }
    p {
        margin: 60px 0 0;
    }
    div {
        position: relative;
        z-index: 2;
    }
    svg {
        position: absolute;
        bottom: -220px;
        left: 0;
        width: 100%;
        min-width: 2000px;
        pointer-events: none;
        z-index: 1;
    }
}

.App {
    position: relative;
    display: block;
    width: 100%;
    max-width: 2000px;
    margin: 0 auto;
    overflow: hidden;
}

.modal-video {
    background-color:rgba($black, 0.9);
    .modal-video-body {
        max-width:80vw;
        .modal-video-movie-wrap {
            border-radius: $borderRadius;
            border: 10px solid $white;
            padding-bottom: 55.5% !important;
            .modal-video-close-btn {
                cursor:pointer;
            }
        }
    }
}

.ReactModal__Body--open {
    .ReactModalPortal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
    }
}

.ReactModal__Content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(38, 48, 59, 0.9);
    .video-container {
        position: relative;
    }
    video {
        display: block;
        width: auto;
        height: 100%;
        max-width: 90%;
        min-height: 70vh;
        margin: 0 auto;
    }
    button {
        position: absolute;
        top: 0;
        right: -45px;
        display: inline-block;
        width: 35px;
        height: 35px;
        border: none;
        background: transparent;
        overflow: hidden;
        cursor: pointer;
        z-index: 2;
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            height: 2px;
            width: 100%;
            margin-top: -6px;
            background: #fff;
            border-radius: 5px;
        }
    }
}