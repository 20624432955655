.container {
    &--vision {
        position: relative;
        padding: 0 0 48rem;
        margin-bottom: -350px;
        background: $midgreen;
        overflow: hidden;
        .top {
            position: relative;
            padding: 65px 0 30px;
            z-index: 3;
            .image {
                position: absolute;
                top: 0;
                left: 0;
                width: 50%;
                height: 670px;
                border-top-right-radius: $borderRadius;
                border-bottom-right-radius: $borderRadius;
                overflow: hidden;
                picture {
                    position: relative;
                    display: flex;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .copy {
                position: relative;
                z-index: 1;
                p {
                    margin-bottom: 65px;
                    color: $white;
                    font-size: 20px;
                    line-height: 35px;
                    &:last-of-type {
                        margin-bottom:0;
                    }
                }
            }
        }
        .bottom {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 0;
            .vision-bottom {
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 1;
            }
        }
    }
    &--why {
        position: relative;
        margin-top: 190px;
        background: $blue;
        .why-top {
            position: absolute;
            bottom: 85%;
            width: 100%;
            height: auto;
            z-index: 2;
        }
        .left-clouds {
            position: absolute;
            top: -200px;
            width: 300px;
            height: auto;
            animation: linear infinite forwards;
            animation-name: clouds;
            animation-duration: 60s;
            user-select: none;
            z-index: 8;
        }
        .right-clouds {
            position: absolute;
            top: -70px;
            width: 300px;
            height: auto;
            animation: linear infinite forwards;
            animation-name: clouds;
            animation-duration: 60s;
            animation-delay: -40s;
            user-select: none;
            z-index: 8;
        }
        .copy {
            position: relative;
            z-index: 8;
            h2 {
                margin-bottom: 2rem;
                color: $black;
            }
        }
        .why-lockup {
            position: relative;
            bottom: 0;
            right: 0;
            width: 100%;
            margin-top: -100px;
            background: $blue;
            text-align: right;
            overflow: hidden;
            svg {
                position: relative;
                bottom: 0;
                right: -90px;
                width: 100%;
                height: auto;
            }
        }
    }
    &--pillars {
        position: relative;
        margin-top: 15px;
        .pillars-top {
            position: absolute;
            bottom: 100%;
            left: 0;
            width: 100%;
            z-index: 1;
        }
        .content {
            position: relative;
            background: $midgreen;
            z-index: 2;
            .heading {
                margin-bottom: 100px;
                h2 {
                    margin-bottom: 0;
                }
                p {
                    margin-bottom: 0;
                    @include azo-regular;
                    color: $white;
                    font-size: 20px;
                    line-height: 35px;
                }
            }
            .pillars-boxes {
                .box {
                    padding: 70px 40px;
                    background: $white;
                    border-radius: $borderRadius;
                    h4 {
                        margin-bottom: 28px;
                        color: $fontdark;
                    }
                    p {
                        margin: 0;
                    }
                }
            }
            .grid-container {
                position: relative;
                z-index: 2;
            }
            .shapes {
                &--left, &--right {
                    position: absolute;
                    bottom: 60px;
                    z-index: 1;
                    .tree {
                        &--small {
                            position: absolute;
                            bottom: 0;
                            width: 108px;
                            height: 204px;
                            z-index: 1;
                        }
                        &--big {
                            position: absolute;
                            bottom: 10px;
                            width: 250px;
                            height: 478px;
                            z-index: 2;
                        }
                    }
                }
                &--left {
                    left: 0;
                    .tree {
                        &--small {
                            left: 0;
                        }
                        &--big {
                            left: 65px;
                        }
                    }
                }
                &--right {
                    right: 0;
                    .tree {
                        &--small {
                            right: 0;
                        }
                        &--big {
                            right: 65px;
                        }
                    }
                }
            }
            .carousel-top {
                position: absolute;
                bottom: -190px;
                z-index: 1;
            }
        }
    }
    &--carousel {
        position: relative;
        background: $blue;
        .grid-container {
            position: relative;
            overflow: hidden;
        }
        .copy {
            margin: 110px 0 73px;
            p {
                @include veneer;
                font-size: 55px;
                line-height: 70px;
                text-transform: uppercase;
                strong {
                    @include veneer;
                    color: $midgreen;
                }
            }
        }
        .carousel {
            padding-top: 190px;
            .slick {
                &-track {
                    display: flex;
                    align-items: center;
                }
                &-slide {
                    position: relative;
                    width: 780px;
                    height: 465px;
                    padding: 20px 15px 30px;
                    opacity: 0.35;
                    transition: $animation;
                    > div {
                        height: 100%;
                    }
                }
                &-current {
                    opacity: 1;
                    caption {
                        opacity: 1;
                    }
                }
                &-dots {
                    top: -55px;
                    bottom: auto;
                    z-index: 10;
                    li {
                        width: 12px;
                        height: 12px;
                        margin: 0 6px;
                    }
                    button {
                        width: 100%;
                        height: 100%;
                        padding: 0;
                        background: $white;
                        border-radius: 100%;
                        opacity: 0.4;
                        transition: $animation;
                        &:before {
                            display: none;
                        }
                        &:hover {
                            opacity: 1;
                        }
                    }
                    .slick-active {
                        button {
                            opacity: 1;
                        }
                    }
                }
                &-arrow {
                    top: -56px;
                    transform: translateX(-50%);
                    width: 30px;
                    height: 30px;
                    z-index: 10;
                    &:before {
                        content: '';
                        display: block;
                        width: 100%;
                        height: 100%;
                        background-size: 30px;
                        background-repeat: no-repeat;
                        transition: $animation;
                    }
                    &:hover {
                        &:before {
                            opacity: 1;
                        }
                    }
                }
                &-prev {
                    left: calc(50% - 104px);
                    &:before {
                        background-image: url('../img/svgs/chevron-left.svg');
                    }
                }
                &-next {
                    left: calc(50% + 104px);
                    right: auto;
                    &:before {
                        background-image: url('../img/svgs/chevron-right.svg');
                    }
                }
            }
            &--item {
                position: relative;
                width: 100%;
                height: 100%;
                border: 10px solid $white;
                border-radius: $borderRadius;
                picture {
                    position: relative;
                    display: flex;
                    width: 100%;
                    height: 100%;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                caption {
                    position: absolute;
                    bottom: -40px;
                    width: 100%;
                    @include azo-regular;
                    color: $white;
                    text-align: center;
                    opacity: 0;
                    transition: $animation;
                }
                .play-button {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 75px;
                    transition: $animation;
                    cursor: pointer;
                    &:hover {
                        width: 90px;
                    }
                }
            }
        }
    }
}